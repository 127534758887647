import { Box, Grid, Button, Stack, Typography, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import { getNotificationsHR, seeNotificationHR } from "../../../utils/altoRiesgo/notificaciones/notificaciones";

const NotificacionesDataTable = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    function get_string(val){
        try{
            return decodeURIComponent(escape(val));
        }catch(err){
            return val;
        }        
    }

    function get_url(val){
        if(val.includes('/alto/alto/blacklist'))
            return "/alto-riesgo/blacklist";
        else if(val.includes('/alto/alto/complementos'))
            return "/alto-riesgo/complementos";
        else if(val.includes('/alto/Contra'))
            return "/alto-riesgo/contracargos";
        else if(val.includes('/alto/alto/detalle/')){
            let aux = val.split('/alto/alto/detalle/');
            return `/alto-riesgo/alto/detail/${aux[1]}`;
        }
    }

    const setRedirect = async (row) => {
        try {
            await seeNotificationHR(row.id_notificacion);
            navigate(get_url(row.url), { replace: true });
        } catch (error) {
            console.error(error);
        }
    };

    const columns = [
        { field: "mensaje", headerName: "Mensaje", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (<Button variant="text" onClick={() => setRedirect(params.row)}>{get_string(params.row.mensaje)}</Button>) },
        { field: "fecha_creacion", headerName: "Fecha", flex: 0.5, align: "center", headerAlign: "center", renderCell: (params) => 
            ( <Typography> <DateInTable dateString={params.row.fecha_creacion} /> </Typography> ),
        }
    ];
    
    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();
      
    useEffect(() => {
    
        let active = true;
    
        (async () => {
            setLoading(true);
            let newRows = await getNotificationsHR(page, user.role.idProfile);
        
            if (!active) {
                return;
            }

            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
    
        return () => {
          active = false;
        };
    
    }, [page]);

    return (
    <>
        <Box style={{ height: 635, width: "100%" }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.id_notificacion}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
                style={{
                    border: "none",
                }}
            />
        </Box>
    </>
    );
};

export default NotificacionesDataTable;