import { Box, Card, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, CircularProgress, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/altoRiesgo/profilesAltoRiesgo";
import Toast from "../../general/Toast";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import BreadCrumb from "../../general/BreadCrumb";
import BlacklistDataTable from "./BlacklistDataTable";
import ExcelFile from "../../general/ExcelFile";
import { getExcelBlacklist, addBlacklist } from "../../../utils/altoRiesgo/blacklist/blacklist";

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";

const itemsBreadCrumbs = [
    {
      label: "Alto Riesgo",
      icon: <GppMaybeIcon sx={{ mr: 0.5 }} fontSize="small" />,
      link: "/alto-riesgo/inicio",
    },
    { label: "Blacklist" },
];

const Blacklist = () => {
    const [nextRender, setNextRender] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [cp, setCP] = useState("");
    const [isELoading, setIsELoading] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [opena, setOpenA] = useState(null);
    const [confalert, setConfAlert] = useState({
        severidad: "success",
        mensaje: "Aclaración registrada con éxito",
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenA = (severidad, mensaje) => {
        setConfAlert({ severidad: severidad, mensaje: mensaje });
        setOpenA(true);
    };

    const handleCloseA = () => {
        setOpenA(false);
    };

    const handleSaveSC = async () => {
        try {
          setIsLoading(true);
          if (email) {
            let resp = await addBlacklist(name ?? "", email, cp ?? "", user.data.awsid);
            if(resp){
                handleOpenA("success", "Cliente registrado en Blacklist");
                handleClose();
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
            else handleOpenA("error", "El cliente no pudo ser registrado, intente de nuevo más tarde");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    const getAllExcelData = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelBlacklist();
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    useEffect(() => {

        if(!profiles.blacklist.includes(user.role.idProfile)){
          navigate("/dashboard", { replace: true });
        }else setNextRender(true);
    
    }, []);

    return (
    <>
        {nextRender && (<>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                <Typography variant="h4" sx={{ my: 2 }}>Blacklist</Typography>
                <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                { excelData.length > 0 ? (<ExcelFile excelData={excelData} fileName={"BlacklistKlaar"} />) : (<LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelData}>Generar excel</LoadingButton>) }
                <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen} sx={{ ml: 2 }} >
                    Crear aclaración
                </Button>
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
                <Card sx={{mb:4}}>
                    <BlacklistDataTable/>
                </Card>
            </motion.div>

            <Dialog open={open} fullWidth={true} onClose={handleClose}>
                <DialogTitle>Agregar cliente a Blacklist</DialogTitle>
                <DialogContent>

                    <TextField fullWidth sx={{ my: 3 }} label="Nombre del cliente" variant="outlined" value={name} onChange={ (e) => setName(e.target.value) } />
                    <TextField fullWidth sx={{ mb: 3 }} label="Correo electrónico" variant="outlined" value={email} onChange={ (e) => setEmail(e.target.value) } />
                    <TextField fullWidth sx={{ mb: 3 }} label="Código Postal" variant="outlined" value={cp} onChange={ (e) => setCP(e.target.value) } />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<SaveIcon />} disabled={!email || !name || !cp} onClick={handleSaveSC}>
                        Guardar registro
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
        </>)}
    </>
    );
};

export default Blacklist;