import { Box, Grid, Button, Stack, Typography, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import ExcelFile from "../../general/ExcelFile";
import { getBlacklistNames, searchBlacklistNames, getExcelBlacklistNames } from "../../../utils/altoRiesgo/blacklistNombres/blacklistNombres";

const BlacklistNombresDataTable = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [isELoading, setIsELoading] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const getAllExcelData = async () => {
    try {
        setIsELoading(true);
        const data = await getExcelBlacklistNames();
        setExcelData(data);
    } catch (error) {
        console.error(error);
    } finally {
        setIsELoading(false);
    }
  };

  const columns = [
    { field: "fecha_alta", headerName: "Fecha alta", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => 
        ( <Typography> <DateInTable dateString={params.row.fecha_alta} /> </Typography> ),
    },
    { field: "name", headerName: "Nombre", flex: 1, align: "center", headerAlign: "center" },
    { field: "father_name", headerName: "Apellido Paterno", flex: 1, align: "center", headerAlign: "center" },
    { field: "mother_name", headerName: "Apellido Materno", flex: 1, align: "center", headerAlign: "center" }
  ];

  if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
    document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();
  
  useEffect(() => {

    let active = true;

    (async () => {
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getBlacklistNames(page);
      } else if (keyWord.length >= 5) {
        setLoading(true);
        newRows = await searchBlacklistNames(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };

  }, [page, keyWord]);

  return (
    <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container sx={{ my: 2 }}>
            <Grid item md={4} sx={{ px: 2 }}>
                <Stack direction="row" spacing={1}>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Buscar registro..."
                    value={keyWord}
                    onChange={(e) => setKeytoSearch(e.target.value)}
                />
                </Stack>
            </Grid>
            <Grid item md sx={{ px: 2, textAlign: "right" }}>
            { excelData.length > 0 ? (<ExcelFile excelData={excelData} fileName={"BlacklistNombresKlaar"} />) : (<LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelData}>Generar excel</LoadingButton>) }
            </Grid>
            </Grid>
        </Box>

        <Box style={{ height: 635, width: "100%" }}>
            <DataGridPro
            rows={rows}
            columns={columns}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.id_name}
            rowCount={total}
            paginationMode="server"
            onPageChange={(newPage) => {
                setPage(newPage);
            }}
            loading={loading}
            disableColumnMenu
            disableSelectionOnClick
            style={{
                border: "none",
            }}
            />
        </Box>

    
    </>
  );
};

export default BlacklistNombresDataTable;