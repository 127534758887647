import { Box, Grid, Button, Stack, Typography, TextField, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import Toast from "../../general/Toast";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import { getComplements, searchComplements, deleteComplement } from "../../../utils/altoRiesgo/complementos/complementos";

const ComplementosComDataTable = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [opena, setOpenA] = useState(null);
  const [openc, setOpenC] = useState(null);
  const [idbl, setIDBL] = useState(0);
  const [sku, setSKU] = useState("");
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "Aclaración registrada con éxito",
  });

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null)
  };

  const handleOpenA = (severidad, mensaje) => {
    setConfAlert({ severidad: severidad, mensaje: mensaje });
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleOpenC = (row) => {
    setIDBL(row.id_complemento);
    setSKU(row.sku);
    setOpenC(true);
  };

  const handleCloseC = () => {
    setOpenC(false);
    setSKU("");
    setIDBL(0);
  };

  const handleDeleteBL = async () => {
    try {
      setIsLoading(true);
      if (idbl && sku) {
        let resp = await deleteComplement(idbl);
        if(resp){
          handleOpenA("success", "Complemento eliminado");
          handleCloseC();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        else handleOpenA("error", "El complemento no pudo ser eliminado, intente de nuevo más tarde");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { field: "fecha_alta", headerName: "Fecha alta", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => 
        ( <Typography> <DateInTable dateString={params.row.fecha_alta} /> </Typography> ),
    },
    { field: "sku", headerName: "SKU", flex: 1, align: "center", headerAlign: "center" },
    { field: "accion", headerName: "Acciones", flex: 0.5, align: "center", headerAlign: "center", sortable: false, renderCell: (params) => (
      <>
        <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />} disableElevation aria-controls={open ? this : undefined} aria-haspopup="menu" onClick={(event) => handleClick(event, params.row.id_complemento)}>Acción</Button>
        <Menu anchorEl={anchorEl} open={open === params.row.id_complemento ? true : false} onClose={handleClose}>
          <MenuItem onClick={() => handleOpenC(params.row)}>Eliminar</MenuItem>
        </Menu>
      </>
    )},
  ];

  if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
    document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();
  
  useEffect(() => {

    let active = true;

    (async () => {
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getComplements(page);
      } else if (keyWord.length >= 5) {
        setLoading(true);
        newRows = await searchComplements(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };

  }, [page, keyWord]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ my: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label="Buscar registro..."
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item md sx={{ px: 2, textAlign: "right" }}></Grid>
        </Grid>
      </Box>

      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_complemento}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          style={{
            border: "none",
          }}
        />
      </Box>

      <Dialog open={openc} fullWidth={true} onClose={handleCloseC}>
        <DialogTitle>Eliminar complemento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Desea eliminar al complemento con SKU: <strong>{sku}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseC}>Cancelar</Button>
          <LoadingButton loading={isLoading} variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDeleteBL}>
            Eliminar
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
    </>
  );
};

export default ComplementosComDataTable;