import { Box, Card, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, CircularProgress, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/altoRiesgo/profilesAltoRiesgo";
import Toast from "../../general/Toast";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import BreadCrumb from "../../general/BreadCrumb";
import BlacklistNombresDataTable from "./BlacklistNombresDataTable";
import { addBlacklistNames, addBlacklistNamesSingle } from "../../../utils/altoRiesgo/blacklistNombres/blacklistNombres";
import ReactFileReader from "react-file-reader";
import Papa from 'papaparse';

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const itemsBreadCrumbs = [
    {
      label: "Alto Riesgo",
      icon: <GppMaybeIcon sx={{ mr: 0.5 }} fontSize="small" />,
      link: "/alto-riesgo/inicio",
    },
    { label: "Blacklist nombres" },
];

const BlacklistNombres = () => {
    const [nextRender, setNextRender] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [app, setApp] = useState("");
    const [apm, setApm] = useState("");
    const [opena, setOpenA] = useState(null);
    const [openb, setOpenB] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [arrayMasive, setArrayMasive] = useState([]);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [confalert, setConfAlert] = useState({
        severidad: "success",
        mensaje: "Aclaración registrada con éxito",
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setName("");
        setApp("");
        setApm("");
    };

    const handleOpenA = (severidad, mensaje) => {
        setConfAlert({ severidad: severidad, mensaje: mensaje });
        setOpenA(true);
    };

    const handleCloseA = () => {
        setOpenA(false);
    };

    const handleOpenB = () => {
        setOpenB(true);
    };

    const handleCloseB = () => {
        setOpenB(false);
        setSelectedFile([]);
        setIsFilePicked(false);
        setArrayMasive([]);
    };

    const handleSaveSC = async () => {
        try {
          setIsLoading(true);
          if (name && app && apm) {
            let aux = {
                name: name,
                father_name: app,
                mother_name: apm
            };
            let resp = await addBlacklistNamesSingle(aux);
            if(resp){
                handleOpenA("success", "Dirección registrada en Blacklist nombres");
                handleClose();
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
            else handleOpenA("error", "La dirección no pudo ser registrada, intente de nuevo más tarde");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    const uploadFile = (files) => {

        setSelectedFile(files[0]);
        setIsFilePicked(true);
        Papa.parse(files[0], {
            complete: function(results) {
                let aux = results.data.map( (row) => { return { name: row[0], father_name: row[1], mother_name: row[2] }; } );
                aux.shift();
                setArrayMasive(aux);
            }
        });
    };

    const handleSaveMasive = async () => {
        try {
          setIsLoading(true);
          if (isFilePicked && selectedFile.type === 'text/csv' && arrayMasive) {
            let resp = await addBlacklistNames(arrayMasive);
            if(resp){
                handleOpenA("success", "Registros guardados con éxito en Blacklist nombres");
                handleCloseB();
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
            else handleOpenA("error", "Los registros no pudieron ser guardados, intente de nuevo más tarde");
          }
          console.log(arrayMasive);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    useEffect(() => {

        if(!profiles.blacklistNom.includes(user.role.idProfile)){
          navigate("/dashboard", { replace: true });
        }else setNextRender(true);
    
    }, []);

    return (
    <>
        {nextRender && (<>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                <Typography variant="h4" sx={{ my: 2 }}>Blacklist nombres</Typography>
                <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                <Button variant="contained" color="info" startIcon={<AddIcon />} onClick={handleOpenB} sx={{ ml: 2 }} >
                    Cargar CSV
                </Button>
                <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen} sx={{ ml: 2 }} >
                    Agregar
                </Button>
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
                <Card sx={{mb:4}}>          
                    <BlacklistNombresDataTable />
                </Card>
            </motion.div>

            <Dialog open={open} fullWidth={true} onClose={handleClose}>
                <DialogTitle>Agregar registro a Blacklist Nombres</DialogTitle>
                <DialogContent>
                    <TextField fullWidth sx={{ my: 3 }} label="Nombre" variant="outlined" value={name} onChange={ (e) => setName(e.target.value) } />
                    <TextField fullWidth sx={{ mb: 3 }} label="Apellido Paterno" variant="outlined" value={app} onChange={ (e) => setApp(e.target.value) } />
                    <TextField fullWidth sx={{ mb: 3 }} label="Apellido Materno" variant="outlined" value={apm} onChange={ (e) => setApm(e.target.value) } />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<SaveIcon />} disabled={!name || !app || !apm} onClick={handleSaveSC}>
                        Guardar registro
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog open={openb} fullWidth={true} onClose={handleCloseB}>
                <DialogTitle>Cargar registros en CSV a Blacklist Nombres</DialogTitle>
                <DialogContent>
                    <div style={{ textAlign: "center" }}>
                        <Button sx={{ textTransform: "none" }} startIcon={<DownloadIcon />} href="https://drive.google.com/uc?id=1-6eY00ZwLNUiY-oSJ6dASVDW2vryFkUb&export=download">Descargar Template</Button>
                    </div>
                    <ReactFileReader handleFiles={uploadFile} fileTypes={".csv"}>
                        <Button sx={{ m: 2, textTransform: "none" }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>{isFilePicked ? "Elegir otro archivo" : "Elegir archivo"}</Button> 
                        {isFilePicked ? selectedFile?.name : "Ningún archivo seleccionado"}
                    </ReactFileReader>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseB}>Cancelar</Button>
                    <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<SaveIcon />} disabled={!isFilePicked || selectedFile.type !== 'text/csv'} onClick={handleSaveMasive}>
                        Guardar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
        </>)}
    </>
    );
};

export default BlacklistNombres;