import axios from "axios";

/**
 * GET
 **/

const getBlacklist = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklist/blacklists/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchBlacklist = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklist/blacklists/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 **/

const addBlacklist = async ( nomCliente, email, cp, idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklist/blacklists/add`;
  try {
    const res = await axios.post(URL, { nomCliente, email, cp, idUser });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("addBlacklist", error);
  }
};

const updateBlacklist = async ( idBlack, nomCliente, email, cp ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklist/blacklists/update`;
  try {
    const res = await axios.post(URL, { idBlack, nomCliente, email, cp });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateBlacklist", error);
  }
};

const deleteBlacklist = async ( idBlack ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklist/blacklists/delete`;
  try {
    const res = await axios.post(URL, { idBlack });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteBlacklist", error);
  }
};

/**
 * EXCEL EXPORT
 **/

const getExcelBlacklist = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklist/blacklists/all/new?page=All`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Nombre cliente": element.nombre_cliente ?? "S/Cliente",
        "Email": element.email,
        "CP": element.cp ?? "S/CP"
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export{
  getBlacklist, searchBlacklist, getExcelBlacklist, addBlacklist, updateBlacklist, deleteBlacklist
};