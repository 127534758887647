import axios from "axios";

/**
 * GET
 **/
const getBlacklistNames = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklistnames/blacklistnames/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchBlacklistNames = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklistnames/blacklistnames/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 **/
const addBlacklistNames = async ( names ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklistnames/blacklistnames/massive/add`;
  try {
    const res = await axios.post(URL, { names });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("addBlacklistNames", error);
  }
};

const addBlacklistNamesSingle = async ( name ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklist/blacklistnames/single/add`;
  try {
    const res = await axios.post(URL, { name });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("addBlacklistNamesSingle", error);
  }
};

/**
 * EXCEL EXPORT
 **/

const getExcelBlacklistNames = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/blacklistnames/blacklistnames/all/new?page=All`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Fecha alta": new Date(element.fecha_alta).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
        "Nombre": element.name,
        "Apellido Paterno": element.father_name,
        "Apellido Materno": element.mother_name,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export{
  getBlacklistNames, searchBlacklistNames, getExcelBlacklistNames, addBlacklistNames, addBlacklistNamesSingle
};