import axios from "axios";

/**
 * GET
 **/

const getChargeback = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/chargeback/chargeback/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchChargeback = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/chargeback/chargeback/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 **/

const addChargeback = async ( mails, idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/chargeback/chargeback/massive/add`;
  try {
    const res = await axios.post(URL, { mails, idUser });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("addChargeback", error);
  }
};

const updateChargeback = async ( mail, idCharge ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/chargeback/chargeback/edit`;
  try {
    const res = await axios.post(URL, { mail, idCharge });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateChargeback", error);
  }
};

const deleteChargeback = async ( idCharge ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/chargeback/chargeback/delete`;
  try {
    const res = await axios.post(URL, { idCharge });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteChargeback", error);
  }
};

/**
 * EXCEL EXPORT
 **/

const getExcelChargeback = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/chargeback/chargeback/all/new?page=All`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Fecha alta": new Date(element.fecha_alta).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
        "Email": element.email
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export{
  getChargeback, searchChargeback, getExcelChargeback, addChargeback, updateChargeback, deleteChargeback
};