import { Box, Card, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, CircularProgress, Button, Tab } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/altoRiesgo/profilesAltoRiesgo";
import ComplementosComDataTable from "./ComplementosComDataTable";
import ComplementosCatDataTable from "./ComplementosCatDataTable";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import BreadCrumb from "../../general/BreadCrumb";
import ExcelFile from "../../general/ExcelFile";
import { getExcelComplements, getExcelCategories, addCategories, addComplement } from "../../../utils/altoRiesgo/complementos/complementos";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";

const itemsBreadCrumbs = [
    {
      label: "Alto Riesgo",
      icon: <GppMaybeIcon sx={{ mr: 0.5 }} fontSize="small" />,
      link: "/alto-riesgo/inicio",
    },
    { label: "Complementos" },
];

const Complementos = () => {
    const [nextRender, setNextRender] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState('1');
    const [open, setOpen] = useState(false);
    const [isELoading, setIsELoading] = useState(false);
    const [excelDataCom, setExcelDataCom] = useState([]);
    const [excelDataCat, setExcelDataCat] = useState([]);

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const getAllExcelDataCom = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelComplements();
            setExcelDataCom(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    const getAllExcelDataCat = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelCategories();
            setExcelDataCat(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    useEffect(() => {

        if(!profiles.complementos.includes(user.role.idProfile)){
          navigate("/dashboard", { replace: true });
        }else setNextRender(true);
    
    }, []);

    return (
    <>
        {nextRender && (<>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                <Typography variant="h4" sx={{ my: 2 }}>Complementos</Typography>
                <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
                <Card sx={{mb:4}}>          
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange}>
                                <Tab label="Complementos" value="1" />
                                <Tab label="Categorías" value="2" />
                            </TabList>

                            <TabPanel value="1">
                                <Box sx={{ display: "flex", justifyContent: "end" }}>
                                    { excelDataCom.length > 0 ? (<ExcelFile excelData={excelDataCom} fileName={"ComplementosAltoRiesgo"} />) : (<LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelDataCom}>Generar excel</LoadingButton>) }
                                    <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen} sx={{ ml: 2 }} >
                                        Agregar complemento
                                    </Button>
                                </Box>
                                <ComplementosComDataTable />
                            </TabPanel>

                            <TabPanel value="2">
                                <Box sx={{ display: "flex", justifyContent: "end" }}>
                                    { excelDataCat.length > 0 ? (<ExcelFile excelData={excelDataCat} fileName={"CategoriasAltoRiesgo"} />) : (<LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelDataCat}>Generar excel</LoadingButton>) }
                                    <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen} sx={{ ml: 2 }} >
                                        Agregar categoría
                                    </Button>
                                </Box>
                                <ComplementosCatDataTable />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Card>
            </motion.div>
        </>)}
    </>
    );
};

export default Complementos;