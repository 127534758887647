import axios from "axios";

/**
 * GET
 **/
const getComplements = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/complements/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchComplements = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/complements/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getCategories = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/categories/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchCategories = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/categories/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 **/
const addComplement = async ( sku, idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/complements/add`;
  try {
    const res = await axios.post(URL, { sku, idUser });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("addComplement", error);
  }
};

const deleteComplement = async ( idComp ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/complements/delete`;
  try {
    const res = await axios.post(URL, { idComp });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteComplement", error);
  }
};

const addCategories = async ( category, idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/categories/add`;
  try {
    const res = await axios.post(URL, { category, idUser });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("addCategories", error);
  }
};

const deleteCategories = async ( idCat ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/categories/delete`;
  try {
    const res = await axios.post(URL, { idCat });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteCategories", error);
  }
};

/**
 * EXCEL EXPORT
 **/
const getExcelComplements = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/complements/all/new?page=All`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Fecha alta": new Date(element.fecha_alta).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
        "SKU": element.sku
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getExcelCategories = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/complements/categories/all/new?page=All`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Fecha alta": new Date(element.fecha_alta).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
        "Categoría": element.categoria
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export{
    getComplements, searchComplements, getCategories, searchCategories,
    getExcelComplements, getExcelCategories, addComplement, deleteComplement,
    addCategories, deleteCategories
};