import axios from "axios";

/**
 * GET
 **/
const getNotificationsHR = async (currentPage, idProfile) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/notifications/notifications/all/new?page=${currentPage}&idProfile=${idProfile}`;
    try {
      const res = await axios(URL);
      return res.data?.success ? res.data : [];
    } catch (error) {
      console.error(error);
    }
};

/**
 * POST
 **/
const seeNotificationHR = async ( idNotif ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/notifications/notifications/set/read`;
    try {
      const res = await axios.post(URL, { idNotif });
      return res.data?.success ? true : false;
    } catch (error) {
      console.error("seeNotificationHR", error);
    }
};

export{
    getNotificationsHR, seeNotificationHR
};